@import "src/styles/helpers";

.checkboxLabel {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer !important;

  :global {
    .MuiTypography-root {
      @include bodyMediumLight(true);
      color: var(--dark) !important;

      &.Mui-disabled {
        color: var(--grey) !important;
      }
    }

    .MuiFormControlLabel-label {
      width: 100% !important;
    }
  }
}

.checkbox {
  &:global {

    &.Mui-disabled {
      cursor: default !important;

      :local {

        .icon {
          color: var(--grey) !important;
        }
      }
    }
  }

  &:hover {

    &:not(:global .Mui-disabled) {
      .icon {
        color: var(--accent-900);
      }
    }
  }

  .icon {
    @include ease;
    color: var(--primary-500);

    svg,
    img {
      width: 100%;
      height: 100%;
    }
  }
}
