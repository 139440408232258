@import "src/styles/helpers";

.select {

  &.disabled {
    opacity: 0.5;

    + .icon {
      color: var(--grey);
    }
  }

  :global {
    .MuiInputLabel-root {
      color: var(--grey) !important;
      @include bodyMediumLight($important: true);
      font-family: Inter, sans-serif !important;
      letter-spacing: normal !important;
      transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms, max-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
      padding: 0 !important;

      &.MuiInputLabel-shrink {
        @include bodySmall($important: true);
        transform: translate(14px, -8px) scale(1) !important;
        color: var(--dark) !important;

        .MuiInputLabel-asterisk {
          color: var(--red) !important;
        }
      }

      &.Mui-disabled {
        color: var(--grey) !important;

        &:hover {
          color: var(--grey) !important;
        }

        &.MuiFormLabel-filled {
          color: var(--dark) !important;

          &:hover {
            color: var(--dark) !important;
          }
        }
      }

      &.Mui-focused {
        color: var(--dark) !important;
      }

      &:not(.MuiInputLabel-shrink) {
        left: 3px !important;
        padding-top: 2px;
      }

      &.Mui-error {
        color: var(--grey) !important;

        &.MuiInputLabel-shrink {
          color: var(--dark) !important;
        }
      }
    }

    .MuiInputBase-root {
      color: var(--dark) !important;
      padding: 0 !important;

      .MuiInputBase-input {
        padding: 14px 28px 14px 18px !important;

        &.Mui-disabled {
          color: var(--grey) !important;
          -webkit-text-fill-color: inherit !important;
        }
      }

      fieldset {
        border-color: var(--secondary-500) !important;
        border-radius: 8px !important;

        legend {
          font-family: Inter, sans-serif !important;
          @include bodySmall($important: true);
          letter-spacing: normal !important;
        }
      }

      &:not(.Mui-disabled):not(.Mui-error):not(.Mui-focused) {

        &:hover {

          fieldset {
            border-color: var(--primary-400) !important;
          }
        }
      }

      &.Mui-disabled {
        background: var(--secondary-100);
        fieldset {
          border-color: var(--secondary-500) !important;
        }

        &:hover {
          fieldset {
            border-color: var(--secondary-500) !important;
          }
        }
      }

      &:focus-within,
      &:focus-visible {

        fieldset {
          border: 1px solid var(--primary-500) !important
        }
      }

      &.Mui-error {

        fieldset {
          border-color: var(--red) !important;
        }
      }
    }

    .MuiAutocomplete-endAdornment {
      right: 18px !important;
    }

    .MuiAutocomplete-clearIndicator {
      display: none;
    }

    .MuiAutocomplete-popupIndicator {
      font-size: unset;
      padding: 0;
    }
  }

  .renderedValue {
    display: flex;
  }
}

.paper {
  position: absolute;
  width: 100%;
  background: var(--white) !important;
  border-radius: 8px !important;
  box-shadow: var(--box-shadow) !important;

  &__content {
    :global {
      .MuiAutocomplete-listbox {
        padding: 0;
        @include scrollbar;
        max-height: 275px;
      }

      .MuiAutocomplete-noOptions {
        @include bodySmall(true);
        color: var(--grey) !important;
        text-align: center !important;
      }
    }

    .option {
      color: var(--dark) !important;
      padding: 14px 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 8px;
      cursor: pointer;

      &.underline:not(:last-child) {
        border-bottom: 1px solid var(--secondary-400);
      }

      &:hover {
        background: var(--secondary-200) !important;
      }

      &.Mui-selected {
        background: var(--white) !important;

        &:hover {
          background: var(--secondary-200) !important;
        }
      }

      &__checkbox {
        display: flex;
        align-items: center;
        gap: 8px;
      }

      &__title {
        @include bodySmall;
        font-family: Inter, sans-serif;
      }

      &__selectedMark {
        color: var(--green);
        width: 16px;
        height: 16px;
      }
    }
  }

  &__footer {
    padding: 16px;
    border-top: 1px solid var(--secondary-400);
  }
}

.tags {
  overflow-x: hidden;
  display: flex;
  gap: 8px;
  padding: 10px 0 10px 18px !important;

  .chip {
    background: var(--secondary-200);
    border-radius: 4px;
    padding: 6px 8px;
    color: var(--dark);
    @include bodySmall;
    display: flex;
    align-items: center;
    gap: 4px;
    flex-shrink: 0;

    &__icon {
      width: 12px;
      height: 12px;
      color: var(--primary-600);

      svg {
        width: 100%;
        height: 100%;
      }
    }

    &__main {
      display: flex;
      align-items: center;
      gap: 8px;
    }

    &__removeButton {
      width: 12px;
      height: 12px;
      color: var(--grey);

      &:hover {
        color: var(--accent-900);
      }

      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
}

.group {
  &__header {
    @include subtitleSmall;
    color: var(--grey);
    padding: 14px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &Info {
      @include bodySmall;
      color: var(--accent-900);
    }
  }
}

.disableRotation {
  transform: none !important;
}

.error {
  color: var(--red);
  margin-top: 4px;
  @include bodySmall
}

.inputIcon {
  color: var(--grey) !important;
  width: 16px;
  height: 16px;

  svg,
  img {
    width: 100%;
    height: 100%;
  }
}

.loader {
  div {
    width: 16px !important;
    height: 16px !important;
  }
}
